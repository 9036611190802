import { useWindowSize } from "@react-hookz/web";
import { useState } from "react";
import { Button, Drawer, IconButton, Tooltip, Typography } from "@/components/atoms";
import { useLazyGetBookingsQuery, useLazyGetBookingsSummaryQuery } from "@/redux/apis/booking/bookingApi";
import { BookingStatus } from "@/redux/slices/booking/types";
import { clsx } from "@/utils";
import { BookingControls } from "./BookingControls";
import { BookingStatusIcon } from "./common/BookingStatusIcon/BookingStatusIcon";
import { BookingCustomizeColumns } from "./features/BookingCustomizeColumns/BookingCustomizeColumns";
import { BookingCustomizeColumnsDrawer } from "./features/BookingCustomizeColumns/BookingCustomizeColumnsDrawer";
import { BookingFilters } from "./features/BookingFilters/BookingFilters";
import { allStatuses } from "./fixtures";
import { useBookingFilters } from "./hooks/useBookingFilters";
import { useBookings } from "./hooks/useBookings";
import { useGetBookingsParams } from "./hooks/useGetBookingsParams";

export const BookingsHeader = () => {
  const { isLoading, page } = useBookings();

  return (
    <>
      <div className="sticky top-0 z-30 bg-neutral-surface-gray pt-4">
        <div className="flex flex-row gap-2 border-b border-neutral-mid-gray pb-2 max-xl:justify-between">
          <div className="flex items-center">
            <Typography variant="h1" className="mr-2 border-r border-neutral-mid-gray pr-2 xl:mr-4 xl:pr-4">
              Bookings
            </Typography>
            <Typography variant="action" className="flex shrink-0  text-neutral-dark-gray">
              <Typography variant="h3" className="text-primary">
                {isLoading ? "-" : page.total}
              </Typography>
              &nbsp; Booked Jobs
            </Typography>
            <Legends />
          </div>
          <FilterAndColumnButtons />
        </div>
        <div className="mt-2.5">
          <BookingFilters.ActiveFilters />
        </div>
      </div>
      <BookingControls />
    </>
  );
};

interface BookingRefreshButtonProps {
  onRefresh?: () => void;
}

const BookingRefreshButton = ({ onRefresh }: BookingRefreshButtonProps) => {
  const { params } = useGetBookingsParams();
  const [refetchBookings] = useLazyGetBookingsQuery();
  const [refetchSummary] = useLazyGetBookingsSummaryQuery();

  const handleRefresh = () => {
    refetchBookings(params);
    refetchSummary(params);
    onRefresh?.();
  };

  return (
    <Button
      variant="tertiary"
      startIcon="Refresh"
      onClick={handleRefresh}
      className="max-md:w-full max-md:justify-start sm:px-2 md:max-xl:[&>span]:hidden"
    >
      Refresh
    </Button>
  );
};

const Legends = () => {
  const { isLoading, summary } = useBookings();
  const { filters, setFilters } = useBookingFilters();

  const applyFilterStatus = (status: BookingStatus) => {
    switch (status) {
      case "awaiting_driver_allocation":
        setFilters({
          ...filters,
          status: filters.status?.includes("awaiting_driver_allocation")
            ? filters.status.filter((i) => i !== "awaiting_driver_allocation")
            : ["awaiting_driver_allocation"],
        });
        break;
      case "driver_allocated_not_confirmed":
        setFilters({
          ...filters,
          status: filters.status?.includes("driver_allocated_not_confirmed")
            ? filters.status.filter((i) => i !== "driver_allocated_not_confirmed")
            : ["driver_allocated_not_confirmed"],
        });

        break;
      case "driver_allocated_confirmed":
        setFilters({
          ...filters,
          status: filters.status?.includes("driver_allocated_confirmed")
            ? filters.status.filter((i) => i !== "driver_allocated_confirmed")
            : ["driver_allocated_confirmed"],
        });
        break;
      case "on_way_to_job":
        setFilters({
          ...filters,
          status: filters.status?.includes("on_way_to_job") ? filters.status.filter((i) => i !== "on_way_to_job") : ["on_way_to_job"],
        });
        break;
      case "commenced":
        setFilters({
          ...filters,
          status: filters.status?.includes("commenced") ? filters.status.filter((i) => i !== "commenced") : ["commenced"],
        });
        break;
      case "complete_awaiting_review":
        setFilters({
          ...filters,
          status: filters.status?.includes("complete_awaiting_review")
            ? filters.status.filter((i) => i !== "complete_awaiting_review")
            : ["complete_awaiting_review"],
        });
        break;
      case "closed":
        setFilters({
          ...filters,
          status: filters.status?.includes("closed") ? filters.status.filter((i) => i !== "closed") : ["closed"],
        });
        break;
      case "cancelled":
        setFilters({
          ...filters,
          status: filters.status?.includes("cancelled") ? filters.status.filter((i) => i !== "cancelled") : ["cancelled"],
        });
        break;
    }
  };

  return (
    <div className="no-scrollbar flex flex-1 items-center gap-1 overflow-scroll rounded-md px-[10px] py-1 max-sm:hidden ">
      {allStatuses.map((i) => (
        <Tooltip content={i.tooltip || i.name} key={i.id} interactive={false}>
          <div
            onClick={() => applyFilterStatus(i.id)}
            className={clsx(
              "flex h-7 cursor-pointer items-center gap-2 whitespace-nowrap rounded border-neutral-gray px-1.5  hover:bg-primary-light",
              filters.status?.includes(i.id) && "bg-primary-light"
            )}
            key={i.id}
          >
            <BookingStatusIcon
              status={{
                color: i.color,
                icon: i.icon,
              }}
            />
            <Typography variant="small">
              <span className="hidden 2xl:inline-flex">{i.legend} :</span> {isLoading ? "-" : summary?.[i.id] ?? 0}
            </Typography>
          </div>
        </Tooltip>
      ))}
    </div>
  );
};

const FilterAndColumnButtons = () => {
  const [openColumns, setOpenColumns] = useState(false);
  const [openMenu, setOpenMenu] = useState(false);
  const { width } = useWindowSize();

  return (
    <>
      {width >= 768 ? (
        <div className="flex flex-1 justify-end gap-2 overflow-auto">
          <BookingFilters.Button />

          {width >= 1280 ? (
            <>
              <Button
                variant="tertiary"
                startIcon="Setting4"
                onClick={() => setOpenColumns(true)}
                className="sm:px-2 md:max-xl:[&>span]:hidden"
              >
                Customize Columns
              </Button>
              <BookingCustomizeColumns open={openColumns} onClose={() => setOpenColumns(false)} />
            </>
          ) : (
            <BookingCustomizeColumnsDrawer open={openColumns} onOpenChange={setOpenColumns} onClose={() => setOpenColumns(false)} />
          )}

          <BookingRefreshButton />
        </div>
      ) : (
        <Drawer title="Options" open={openMenu} onOpenChange={setOpenMenu}>
          <IconButton
            variant="secondary"
            iconName="More"
            className="h-[34px] border-none !bg-transparent xl:hidden [&>svg]:fill-black"
            size="sm"
            iconSize="sm"
          />
          <div className="flex -translate-x-2 flex-col items-start gap-3">
            <BookingFilters.DrawerButton onClose={() => setOpenMenu(false)} />
            <BookingRefreshButton onRefresh={() => setOpenMenu(false)} />
            <BookingCustomizeColumnsDrawer open={openColumns} onOpenChange={setOpenColumns} onClose={() => setOpenColumns(false)} />
          </div>
        </Drawer>
      )}
    </>
  );
};
