import { format } from "date-fns";
import { isEmpty } from "ramda";
import { useMemo, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Button, DropdownSelect, Icon, Loading, Panel, Spinner, TextInput, Tooltip, Typography } from "@/components/atoms";
import { useOperator, useProfile } from "@/hooks";
import { useGetCountryStatesQuery } from "@/redux/apis/resource/resourceApi";
import { useCreateVehicleMutation } from "@/redux/apis/vehicle/vehicleApi";
import { Vehicle } from "@/redux/slices/vehicle/types";
import { addToast, getAssetUrl } from "@/utils";
import { getCountryFlagSrc } from "@/utils/getCountryFlagSrc";
import { PageHeader } from "../../PageHeader/PageHeader";
import { VehicleVerificationErrorAlert } from "./common/VehicleVerificationErrorAlert";
import { getVerificationErrorType } from "./helpers";
import { VehicleInArchiveModal } from "./modals/VehicleInArchiveModal";

export const AddVehiclePanel = () => {
  return (
    <div className="mb-12 flex flex-1 flex-col">
      <PageHeader.Actions />
      <Panel className="mx-auto flex w-full max-w-[570px] flex-col items-center p-[30px]">
        <div className="mb-4 flex h-16 w-16 items-center justify-center rounded-full bg-neutral-gray">
          <Icon name="Car" size="xl" className="text-neutral-dark-gray" />
        </div>
        <Typography variant="title" className="mb-1.5">
          Add Vehicle
        </Typography>
        <Typography variant="action">Enter the vehicle details below</Typography>
        <Form />
      </Panel>
    </div>
  );
};

const Form = () => {
  const navigate = useNavigate();
  const inputRef = useRef<HTMLInputElement>(null);
  const { country } = useOperator();
  const { isFetching, data: response } = useGetCountryStatesQuery(country);
  const [verifyVehicle, { isLoading, error, reset }] = useCreateVehicleMutation();
  const [pendingVehicle, setPendingVehicle] = useState<Vehicle | undefined>(undefined);
  const [numberPlate, setNumberPlate] = useState<string>("");
  const [state, setState] = useState<string>("");

  const options = useMemo(
    () => response?.data.states.map((state) => ({ name: state.name, value: state.id })) ?? [],
    [response?.data.states]
  );

  const errorType = useMemo(() => {
    if (!error) return undefined;
    return getVerificationErrorType(error);
  }, [error]);

  const handleReactivate = () => {
    if (!errorType) return;

    const vehicleId = errorType.split("-")[2];
    navigate(`../../archived-vehicles/?filter=${vehicleId}`);
  };

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (!numberPlate || !state) return;

    verifyVehicle({
      dry_run: true,
      registration_number: numberPlate,
      state_uuid: state,
    })
      .unwrap()
      .then((res) => {
        setPendingVehicle(res);
      })
      .catch(() => {
        console.warn("Failed to verify vehicle");
      });
  };

  if (pendingVehicle)
    return (
      <VehicleData
        vehicle={pendingVehicle}
        reset={() => {
          setPendingVehicle(undefined);
          reset();
        }}
      />
    );

  return (
    <>
      <div className="relative w-full">
        {isLoading && <Loading />}

        <div className="flex max-w-lg flex-col">
          <form className="mt-6 flex flex-col gap-4" onSubmit={handleSubmit}>
            <label htmlFor="number-plate" className="flex flex-col gap-1">
              <Typography variant="paragraph">Vehicle Number Plate</Typography>
              <TextInput
                title="Only Alphabets and Numbers are allowed"
                pattern="[a-zA-Z0-9\-]+"
                placeholder="000-0000"
                id="number-plate"
                value={numberPlate}
                onChange={(e) => setNumberPlate(e.target.value)}
                hasError={errorType === "verification-failed"}
                hasWarning={errorType === "vehicle-exists" || errorType === "vehicle-exists-duplicate"}
                required
                autoFocus
                ref={inputRef}
                maxLength={10}
              />
            </label>
            <label htmlFor="state" className="flex flex-col gap-1">
              <Typography variant="paragraph">Select the State of your Vehicle</Typography>

              <DropdownSelect
                id="state"
                className="w-full"
                placeholder="Select State"
                options={options}
                value={state}
                onChange={setState}
                disabled={isEmpty(options) || isFetching}
              />
            </label>
            {errorType && <VehicleVerificationErrorAlert errorType={errorType} />}
            <div className="mt-2">
              <Button type="submit" variant="primary" size="lg" className="w-full" disabled={isLoading}>
                {isLoading && <Spinner />}
                Continue
              </Button>
            </div>
          </form>
        </div>
      </div>
      <VehicleInArchiveModal
        open={Boolean(errorType && errorType.includes("vehicle-archived"))}
        onClose={() => reset()}
        onReactivate={handleReactivate}
      />
    </>
  );
};

const VehicleData = ({ vehicle, reset }: { vehicle: Vehicle; reset: () => void }) => {
  const { dateFormat } = useProfile();
  const navigate = useNavigate();
  const [verifyVehicle, { isLoading, error }] = useCreateVehicleMutation();

  const errorType = useMemo(() => {
    if (!error) return undefined;

    return getVerificationErrorType(error);
  }, [error]);

  const handleClick = () => {
    verifyVehicle({
      dry_run: false,
      registration_number: vehicle.registrationNumber,
      state_uuid: vehicle.state.uuid,
    })
      .unwrap()
      .then((res) => {
        addToast("success", "Vehicle has been successfully added in My Vehicles.");
        navigate(`../${res.uuid}/edit?newVehicle=1`);
      })
      .catch(() => {
        console.warn("Failed to add vehicle");
      });
  };

  const renderStateFlag = () => {
    const flagSrc = getCountryFlagSrc("AU");

    return (
      <div className="ml-3 flex gap-2 pl-3">{flagSrc && <img src={flagSrc} className="h-[18px] w-[18px] rounded-full object-cover" />}</div>
    );
  };

  return (
    <div className="mt-5 w-full">
      <Typography variant="paragraph" className="text-neutral-dark-gray">
        Here’s the data we gathered from the inputs you provided:
      </Typography>

      <div className="mt-3 rounded bg-neutral-surface-gray">
        <div className="flex flex-1 gap-3 border-b border-neutral-mid-gray p-5">
          <div className="flex items-center justify-center rounded-full border border-solid border-neutral-mid-gray p-2">
            <img
              src={getAssetUrl(vehicle.model.avatar, { height: 56, width: 56, background: "transparent", fit: "contain" })}
              className="h-[28px] w-[28px] rounded-full "
            />
          </div>
          <div className="flex flex-1 flex-col">
            <Typography variant="title" className="text-neutral-black">
              {vehicle.model.make} {vehicle.model.model}
            </Typography>
            <Typography variant="action" className="flex items-center gap-1 text-neutral-dark-gray">
              Number Plate: {vehicle.registrationNumber}
            </Typography>
          </div>
        </div>

        <div className="mt-3 grid grid-cols-2 gap-3 p-5">
          <div className="col-span-1 flex items-center">
            <Typography className="flex items-center text-neutral-dark-gray">Year</Typography>
          </div>
          <div className="col-span-1 flex items-center justify-end">
            <Typography className="inline-block capitalize text-neutral-black">{vehicle.year}</Typography>
          </div>
          <div className="col-span-1 flex items-center">
            <Typography className="flex items-center text-neutral-dark-gray">Color</Typography>
          </div>
          <div className="col-span-1 flex items-center justify-end">
            <Typography className=" inline-flex items-center capitalize text-neutral-black">
              {vehicle.color.toLowerCase()}
              <div className="ml-2 h-3 w-3 rounded-full border border-black" style={{ backgroundColor: vehicle.color.toLowerCase() }} />
            </Typography>
          </div>
          <div className="col-span-1 flex items-center">
            <Typography className="flex items-center text-neutral-dark-gray">Capacity</Typography>
          </div>
          <div className="col-span-1 flex items-center justify-end">
            <Typography className="inline-block capitalize text-neutral-black">{vehicle.capacity.pax}</Typography>
          </div>
          <div className="col-span-1 flex items-center">
            <Typography className="flex items-center text-neutral-dark-gray">Engine Type</Typography>
          </div>
          <div className="col-span-1 flex items-center justify-end">
            <Typography className="inline-block capitalize text-neutral-black">{vehicle.model.engine.toLowerCase()}</Typography>
          </div>

          <div className="col-span-1 flex items-center">
            <Typography className="flex items-center text-neutral-dark-gray">VIN number</Typography>
          </div>
          <div className="col-span-1 flex items-center justify-end">
            <Typography className="inline-block capitalize text-neutral-black">{vehicle.vin}</Typography>
          </div>

          <div className="col-span-1 flex items-center">
            <Typography className="flex items-center text-neutral-dark-gray">Registration Expiry</Typography>
          </div>
          <div className="col-span-1 flex items-center justify-end">
            <Typography className="inline-block capitalize text-neutral-black">
              {vehicle.registration.expiryDate ? format(new Date(vehicle.registration.expiryDate), dateFormat) : "-"}
            </Typography>
          </div>
          <div className="col-span-1 flex items-center">
            <Typography className="flex items-center text-neutral-dark-gray">State</Typography>
          </div>
          <div className="col-span-1 flex items-center justify-end">
            <Typography className="inline-flex items-center gap-1 capitalize text-neutral-black">
              {renderStateFlag()}
              {vehicle.state.abbreviation}
            </Typography>
          </div>
          <div className="col-span-1 flex items-center">
            <Typography className="flex items-center text-neutral-dark-gray">
              Network Eligibility
              <Tooltip
                interactive
                content={
                  <div className="max-w-[295px] font-medium">
                    This helps the system match your vehicle accurately within the platform and with external networks, ensuring proper job
                    allocations. <br />
                    <br />
                    <a
                      href="https://rideminder.freshdesk.com/en/support/solutions/articles/51000423941-vehicle-eligibility"
                      rel="noreferrer"
                      target="_blank"
                      className="underline"
                    >
                      {" "}
                      Learn more about Network Eligibility
                    </a>
                  </div>
                }
                placement="top"
              >
                <Icon name="InfoCircle" variant="Bold" className="ml-1 inline-block -rotate-180 text-neutral-dark-gray" size="sm" />
              </Tooltip>
            </Typography>
          </div>
          <div className="col-span-1 flex items-center justify-end">
            <Typography className="inline-flex items-center gap-1 capitalize text-neutral-dark-gray">
              {vehicle.model.class.toLowerCase()}
            </Typography>
          </div>
        </div>
      </div>

      {errorType && <VehicleVerificationErrorAlert errorType={errorType} />}

      <div className="mt-5 w-full">
        <Typography variant="paragraph" className="text-center text-neutral-dark-gray">
          If the information is incorrect, please email{" "}
          <a href="mailto:support@rideminder.com" target="_blank" rel="noreferrer" className="underline">
            support@rideminder.com
          </a>
        </Typography>
        <div className="mt-4 flex items-center justify-end gap-3">
          <Button variant="secondary" size="lg" onClick={() => reset()}>
            Back
          </Button>
          <Button variant="primary" size="lg" onClick={handleClick} disabled={isLoading}>
            {isLoading ? <Spinner /> : "Add Vehicle"}
          </Button>
        </div>
      </div>
    </div>
  );
};
