import { DataTableColumnDef } from "@/components/molecules";
import { MyVehicleTableData, VehicleRegistrationStatus } from "./types";

export const myVehiclesColumns: DataTableColumnDef<MyVehicleTableData>[] = [
  {
    header: "Year",
    size: 100,
    accessorKey: "year",
  },
  {
    header: "Make",
    accessorKey: "make",
    cell: (props) => props.row.original.make,
    size: 150,
  },
  {
    header: "Model",
    accessorKey: "model",
    size: 150,
  },
  {
    header: "Number Plate",
    accessorKey: "registration_number",
    cell: (props) => props.row.original.numberPlate,
    size: 150,
  },
  {
    header: "Nickname",
    size: 150,
    id: "identifier",
    accessorKey: "nickname",
  },
  {
    header: "Color",
    id: "color",
    cell: (props) => props.row.original.color,
    size: 150,
  },
  {
    header: "Currently Assigned Driver",
    id: "driver",
    cell: (props) => props.row.original.driver,
    size: 150,
  },
  {
    header: "Actions",
    id: "actions",
    cell: (props) => props.row.original.actions,
    maxSize: 75,
  },
];

export const statusStyles: Record<VehicleRegistrationStatus, string> = {
  verified: "hover:border-neutral-surface-gray !bg-neutral-surface-gray",
  expiring: "hover:border-warning !bg-warning-light hover:!bg-warning-light",
  expired: "hover:border-danger !bg-danger-light hover:!bg-danger-light",
  unverified: "hover:border-neutral-surface-gray !bg-neutral-surface-gray",
  "missing-details": "hover:border-neutral-surface-gray !bg-neutral-surface-gray",
};
