import { format } from "date-fns";
import { Icon, IconButton } from "@/components/atoms";
import { useProfile } from "@/hooks";
import { useLazyRequestVehicleDocumentQuery } from "@/redux/apis/document/documentApi";
import { downloadFile } from "@/utils";
import { VehicleDetailCard } from "../common/VehicleDetailCard";
import { useViewVehicleContext } from "../context";

export const VehicleAccreditationDetailCard = () => {
  const { dateFormat } = useProfile();
  const { vehicle } = useViewVehicleContext();
  const [requestDocument, { isLoading }] = useLazyRequestVehicleDocumentQuery();

  const accreditationDoc = vehicle.documents.find((doc) => doc.documentType === "ACCREDITATION");

  const handleDownload = () => {
    if (!accreditationDoc) return;

    requestDocument({
      id: accreditationDoc.uuid,
      fileType: "pdf", //todo: get file type from document
      vehicleId: vehicle.uuid,
    })
      .unwrap()
      .then((res) => {
        downloadFile(res.filename, res.uuid);
      });
  };

  const ActionButton = () => {
    if (!accreditationDoc) return null;

    return (
      <IconButton
        disabled={isLoading}
        iconName="ImportCurve"
        className="!bg-transparent p-1.5 text-neutral-black"
        iconSize="lg"
        variant="tertiary"
        onClick={handleDownload}
      />
    );
  };

  return (
    <VehicleDetailCard
      title="Vehicle Accreditation"
      description={
        accreditationDoc && accreditationDoc.expiryDate ? `Expires at: ${format(new Date(accreditationDoc.expiryDate), dateFormat)}` : "N/A"
      }
      icon={<Icon name="file-pdf" isCustom size="xl" className="text-neutral-black" />}
      action={<ActionButton />}
      actionClick={accreditationDoc ? handleDownload : undefined}
    />
  );
};
