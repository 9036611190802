import { useMemo } from "react";
import Confetti from "@/assets/images/signup/confetti.svg";
import { Button, Icon, Loading, Modal, Spinner, Typography } from "@/components/atoms";
import { getErrorMessages } from "@/helpers/reduxHelpers";
import { useGetVehicleFormOptionsQuery, useVerifyVehicleMutation } from "@/redux/apis/vehicle/vehicleApi";
import { Vehicle } from "@/redux/slices/vehicle/types";
import { addToast, formatCurrency } from "@/utils";
import { VehicleVerificationErrorAlert } from "../../AddVehicle/common/VehicleVerificationErrorAlert";
import { getVerificationErrorType } from "../../AddVehicle/helpers";

interface FirstTimeVerificationModalProps extends React.HTMLAttributes<HTMLDivElement> {
  vehicle: Vehicle;
  open: boolean;
  onClose: () => void;
}

export const FirstTimeVerificationModal = ({ vehicle, open, onClose, ...props }: FirstTimeVerificationModalProps) => {
  const [verifyVehicle, { isLoading, error }] = useVerifyVehicleMutation();
  const { data } = useGetVehicleFormOptionsQuery();

  const fee = useMemo(() => {
    if (!data) return 0;

    return data.fees.verificationFee;
  }, [data]);

  const errorType = useMemo(() => {
    if (!error) return undefined;

    return getVerificationErrorType(error);
  }, [error]);

  const handleVerify = () => {
    verifyVehicle(vehicle.uuid)
      .unwrap()
      .then(() => {
        addToast("success", "Vehicle verified successfully");
        onClose();
      })
      .catch((e) => {
        getErrorMessages(e).forEach((message) => addToast("danger", message));
        console.warn("Failed to verify vehicle");
      });
  };

  const modelMake = `${vehicle.model.make} ${vehicle.model.model}`;

  return (
    <>
      <Modal open={open} onClose={onClose} className="!max-w-[550px] !p-5">
        <div className="relative" {...props}>
          {isLoading && <Loading />}
          <div className="mt-5 flex justify-end">
            <Icon name="close" isCustom className="flex cursor-pointer justify-end" onClick={onClose} />
          </div>
          <div className="flex max-w-lg flex-col">
            <div className=" flex flex-col items-center">
              <img src={Confetti} alt="Confetti" className="h-32 w-32" />
              <Typography variant="title" className="flex-1">
                Congratulations!
              </Typography>
            </div>
            <div className="mt-3 ">
              <Typography variant="paragraph" className="px-20 text-center">
                We’ll verify the vehicle registration and expiry date. Just finalize the details to complete the process.
              </Typography>
            </div>
          </div>
          <div className="mt-5 rounded bg-neutral-surface-gray p-2.5">
            <Typography variant="paragraph" className="text-neutral-dark-gray">
              {modelMake} VIN
            </Typography>
            <Typography variant="h3" className="uppercase">
              {vehicle.vin}
            </Typography>
          </div>
          {errorType && <VehicleVerificationErrorAlert errorType={errorType} />}

          <div className="mt-6">
            <Button variant="primary" onClick={handleVerify} size="lg" className="w-full" disabled={isLoading}>
              {isLoading ? <Spinner /> : `Verify for ${formatCurrency(fee, 2, "AUD")}`}
            </Button>
            <Typography variant="small" className="my-3 px-16 text-center text-neutral-dark-gray">
              A {formatCurrency(fee, 2, "AUD")} fee will be debited during the daily account processing.
            </Typography>
          </div>
        </div>
      </Modal>
    </>
  );
};
